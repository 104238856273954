@font-face {
  font-family: "Bitter";
  src: local("Bitter"), url("./fonts/Bitter-Regular.woff") format("truetype");
}
html {
  font-family: "Open Sans", sans-serif;
  color: #ebdbb2;
  background-color: #282828;
}
body {
  margin: 0 auto;
  max-width: 1100px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  word-wrap: break-word;
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-size: 16px;
}
p {
  margin: 1em 0;
}
a {
  color: #fabd2f;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
h1, h2, h3, h4, h5, h6 {
  font-family: "Bitter", serif;
  font-weight: normal;
  line-height: 1.1;
  margin-top: 1.4em;
}
h2 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
ol, ul {
  padding-left: 1.7em;
  margin-top: 1em;
}
li > ol, li > ul {
  margin-top: 0;
}
code {
  font-family: monospace;
  font-size: 90%;
  margin: 0;
  padding: 2px;
  background: #1d2021;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
table {
  width: 100%;
  border-collapse: collapse;
}
table tr:nth-child(2n) {
  background: #1d2021;
}

